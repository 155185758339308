import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import API from '../components/api/api';
import deskImage from '../constants/login.jpeg'; // Adjust the path as necessary
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LoginPage = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await API.post('/auth/login', { username, password });
      localStorage.setItem('token', data.token);
      localStorage.setItem("userInfo", JSON.stringify(data));
      onLogin();  // Call onLogin after successful login
      navigate('/dashboard');
    } catch (error) {
      toast.error('Login failed. Please check your username and password.');
    }
  };

  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      await API.post('/auth/forgot-password', { email });
      toast.success('Password reset link has been sent to your email.');
    } catch (error) {
      toast.error('Error sending password reset link.');
    }
  };

  return (
    <div className="flex h-screen">
      {/* Left Side Image Section */}
      <div className="hidden lg:flex lg:w-1/2 bg-cover bg-center" style={{ backgroundImage: `url(${deskImage})` }}>
        {/* Optional background image */}
      </div>

      {/* Right Side Form Section */}
      <div className="w-full lg:w-1/2 max-w-md mx-auto p-8 md:p-12 lg:p-16 flex items-center justify-center">
        <div className="flex flex-col items-center w-full">
          {/* Logo Section */}
          <div className="mb-6">
            <img src="https://png.pngtree.com/png-clipart/20211017/original/pngtree-school-logo-png-image_6851480.png" alt="Logo" className="w-16 h-16" />
          </div>

          {!isForgotPassword ? (
            // Login Form
            <form onSubmit={handleLoginSubmit} className="w-full space-y-6">
              <h2 className="text-2xl font-bold text-center">Sign in to your account</h2>

              {/* Username Input */}
              <div>
                <input
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Email address"
                  required
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-200"
                />
              </div>

              {/* Password Input */}
              <div className="relative">
                <input
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  required
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-200"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-0 px-3 py-2 text-sm text-blue-500 focus:outline-none"
                >
                  {showPassword ? 'Hide' : 'Show'}
                </button>
              </div>

              {/* Forgot Password Link */}
              <div className="text-right">
                <button
                  type="button"
                  onClick={() => setIsForgotPassword(true)}
                  className="text-blue-500"
                >
                  Forgot password?
                </button>
              </div>

              {/* Sign In Button */}
              <button
                type="submit"
                className="w-full py-2 px-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-200"
              >
                Sign in
              </button>
            </form>
          ) : (
            // Forgot Password Form
            <form onSubmit={handleForgotPasswordSubmit} className="w-full space-y-6">
              <h2 className="text-2xl font-bold text-center">Forgot Password</h2>

              {/* Email Input */}
              <div>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email address"
                  required
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-200"
                />
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                className="w-full py-2 px-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-200"
              >
                Send Password Reset Link
              </button>

              {/* Back to Login Link */}
              <div className="text-right">
                <button
                  type="button"
                  onClick={() => setIsForgotPassword(false)}
                  className="text-blue-500"
                >
                  Back to login
                </button>
              </div>
            </form>
          )}
        </div>
      </div>

      {/* Toast Container */}
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} closeOnClick pauseOnFocusLoss draggable pauseOnHover />
    </div>
  );
};

export default LoginPage;
