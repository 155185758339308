import React, { useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

const PasswordResetPage = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(location.search);
  const token = query.get('token');

  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      const response = await axios.post('https://fee-6jw9.onrender.com/api/auth/reset-password', {
        token,
        newPassword
      });
      setSuccess('Password has been reset successfully.');
      setTimeout(() => navigate('/'), 1000); // Redirect after success
    } catch (err) {
      setError('Error resetting password.');
    }
  };

  // Determine the border color based on password match
  const confirmPasswordBorderColor = confirmPassword
  ? newPassword === confirmPassword
  ? 'border-green-500 focus:ring focus:ring-green-200'
  : 'border-red-500 focus:ring focus:ring-red-200'
: 'border-blue-300 focus:ring focus:ring-blue-200';


  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 bg-white shadow-md rounded-lg">
        <h2 className="text-2xl font-bold mb-4 text-center">Reset Password</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {success && <p className="text-green-500 mb-4">{success}</p>}
        
        <div className="mb-4 relative">
          <input
            type={showPassword ? 'text' : 'password'}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="Enter new password"
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-200"
          />
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="absolute inset-y-0 right-0 px-3 py-2 text-sm text-blue-500 focus:outline-none"
          >
            {showPassword ? 'Hide' : 'Show'}
          </button>
        </div>

        <div className="mb-4 relative">
          <input
            type={showConfirmPassword ? 'text' : 'password'}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm new password"
            className={`w-full px-4 py-2 border rounded-lg focus:outline-none  ${confirmPasswordBorderColor}`}
          />
          <button
            type="button"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            className="absolute inset-y-0 right-0 px-3 py-2 text-sm text-blue-500 focus:outline-none"
          >
            {showConfirmPassword ? 'Hide' : 'Show'}
          </button>
        </div>

        <button
          onClick={handleResetPassword}
          className="w-full py-2 px-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-200"
        >
          Reset Password
        </button>
      </div>
    </div>
  );
};

export default PasswordResetPage;
