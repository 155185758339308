import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import API from "../components/api/api";
import { useDarkMode } from "../Context/context";
import { faDownload, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import jsPDF from "jspdf";
import "jspdf-autotable";

const PaymentEntry = () => {
  const [students, setStudents] = useState([]);
  const [feeStructures, setFeeStructures] = useState([]);
  const { isDarkMode } = useDarkMode();
  const [searchQuery, setSearchQuery] = useState("");
  const [yearFilter, setYearFilter] = useState("");
  const [classFilter, setClassFilter] = useState("");
  const [studentId, setStudentId] = useState("");
  const [amount, setAmount] = useState("");
  const [method, setMethod] = useState("");
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const navigate = useNavigate();
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [showHistoryModal, setShowHistoryModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const [totalPages, setTotalPages] = useState(1);

  const handleViewPaymentHistory = async (student) => {
    setStudentId(student._id);
    setSelectedStudent(student);
    setShowHistoryModal(true);

    try {
      const { data } = await API.get(`/payments/${student._id}`);
      setPaymentHistory(data);
    } catch (error) {
      console.error("Error fetching payment history:", error);
      alert(
        "There was an error fetching the payment history. Please try again."
      );
    }
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const { data } = await API.get(
          `/students?page=${currentPage}&className=${classFilter}&academicYear=${yearFilter}&searchName=${searchQuery}`
        );
        console.log(data);
        setStudents(data.students);
        setTotalPages(data.totalPages);
      } catch (error) {
        console.error("Error fetching students:", error);
      }
    };

    const fetchFeeStructures = async () => {
      try {
        const { data } = await API.get("/fee-structures");
        setFeeStructures(data);
      } catch (error) {
        console.error("Error fetching fee structures:", error);
      }
    };

    fetchStudents();
    fetchFeeStructures();
  }, [currentPage, classFilter, searchQuery, yearFilter]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleYearFilterChange = (e) => {
    setYearFilter(e.target.value);
  };

  const handleClassFilterChange = (e) => {
    setClassFilter(e.target.value);
  };

  const handleRecordPayment = (student) => {
    setStudentId(student._id);
    setSelectedStudent(student);
    setShowPaymentModal(true);
  };

  const handleSubmitPayment = async (e) => {
    e.preventDefault();
    try {
      await API.post("/payments/record-payment", { studentId, amount, method });
      alert("Payment recorded successfully");
      setShowPaymentModal(false);
      // Refresh students data
      const { data } = await API.get("/students");
      setStudents(data.students);
    } catch (error) {
      console.error("Error recording payment:", error);
      alert("There was an error recording the payment. Please try again.");
    }
  };

  // const filteredStudents = students.filter((student) => {
  //   const matchesSearchQuery =
  //     student.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //     student.class.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //     student.academicYear.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //     student.feeStructure.toString().includes(searchQuery);

  //   const matchesClassFilter = classFilter
  //     ? student.class === classFilter
  //     : true;
  //   const matchesYearFilter = yearFilter
  //     ? student.academicYear === yearFilter
  //     : true;

  //   return matchesSearchQuery && matchesClassFilter && matchesYearFilter;
  // });

  const formatIndianNumber = (number) => {
    if (number === undefined || number === null) return "";
    const numberString = number.toString();
    const lastThree = numberString.slice(-3);
    const others = numberString.slice(0, -3);
    if (others !== "") {
      const formattedOthers = others.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
      return `${formattedOthers},${lastThree}`;
    }
    return lastThree;
  };
  const downloadFunction = (payment) => {
    const doc = new jsPDF();

    // Assuming selectedStudent contains the required student details
    const { amount, date } = payment;

    // Format the date as dd-mm-yyyy
    const formattedDate = new Date(date).toLocaleDateString("en-GB");

    // Add a heading
    doc.setFontSize(18);
    doc.setTextColor(40, 40, 40);
    doc.text("Fees Invoice", 105, 20, null, null, "center");

    // Add a horizontal line
    doc.setLineWidth(0.5);
    doc.line(20, 25, 190, 25);

    // Add student details section
    doc.setFontSize(12);
    doc.setTextColor(60, 60, 60);
    doc.text(`Student Name:`, 20, 40);
    doc.text(`Class:`, 20, 50);
    doc.text(`Academic Year:`, 20, 60);

    // Add payment details section
    doc.text(`Amount Paid:`, 20, 80);
    doc.text(`Date:`, 20, 90);

    // Add corresponding data with bold font
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.setTextColor(0, 0, 0);
    doc.text(selectedStudent.name, 70, 40);
    doc.text(selectedStudent.class, 70, 50);
    doc.text(selectedStudent.academicYear, 70, 60);
    doc.text(`${amount} Rs`, 70, 80);
    doc.text(formattedDate, 70, 90);

    // Add a footer
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.setTextColor(100, 100, 100);
    doc.text("Thank you for your payment!", 105, 130, null, null, "center");

    // Save the PDF
    doc.save(`${selectedStudent.name}_Invoice.pdf`);
  };


  const uniqueClasses = Array.from(
    new Set(feeStructures.map((fee) => fee.class))
  );
  const uniqueYears = Array.from(
    new Set(feeStructures.map((fee) => fee.academicYear))
  );

  return (
    <div
      className={`p-6 min-h-full bg-gray-200 overflow-hidden ${
        isDarkMode ? "dark-mode" : ""
      }`}
    >
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl mb-5 font-bold text-gray-700">Payment Entry</h1>
      </div>

      <div className="flex items-center mb-4 space-x-4">
        <div className="relative w-1/3">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 bg-slate-50 text-gray-700 shadow-sm"
          />
          <FontAwesomeIcon
            icon={faSearch}
            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
          />
        </div>
        <select
          value={classFilter}
          onChange={handleClassFilterChange}
          className="p-2 border border-gray-300 rounded bg-slate-50 text-gray-700"
        >
          <option value="">All Groups</option>
          {uniqueClasses.map((uniqueClass, index) => (
            <option key={index} value={uniqueClass}>
              {uniqueClass}
            </option>
          ))}
        </select>
        <select
          value={yearFilter}
          onChange={handleYearFilterChange}
          className="p-2 border border-gray-300 rounded bg-slate-50 text-gray-700"
        >
          <option value="">All Academic Years</option>
          {uniqueYears.map((uniqueYear, index) => (
            <option key={index} value={uniqueYear}>
              {uniqueYear}
            </option>
          ))}
        </select>
      </div>

      <table className="min-w-full bg-white shadow-md rounded overflow-hidden">
        <thead className="text-white bg-gray-800">
          <tr>
            <th className="py-4 px-4">Name</th>
            <th className="py-4 px-4">Group</th>
            <th className="py-4 px-4">Academic Year</th>
            <th className="py-4 px-4">Fee Structure</th>
            <th className="py-4 px-4">Amount Paid</th>
            <th className="py-4 px-4">Due Amount</th>
            <th className="py-4 px-4">Actions</th>
          </tr>
        </thead>
        <tbody className="bg-gray-100">
          {students.map((student) => (
            <tr key={student._id} className="border-b text-gray-700">
              <td className="py-4 px-4">{student.name}</td>
              <td className="py-4 px-4">{student.class}</td>
              <td className="py-4 px-4">{student.academicYear}</td>
              <td className="py-4 px-4">
                ₹{formatIndianNumber(student.feeStructure)}
              </td>
              <td className="py-4 px-4">
                ₹{formatIndianNumber(student.amountPaid)}
              </td>
              <td className="py-4 px-4">
                ₹{formatIndianNumber(student.dueAmount)}
              </td>
              <td className="py-4 px-4">
                <button
                  onClick={() => handleRecordPayment(student)}
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mr-2"
                >
                  Record Payment
                </button>
                <button
                  onClick={() => handleViewPaymentHistory(student)}
                  className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                >
                  View Payment History
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-center my-4">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="mx-2 px-4 py-2 bg-red-500 text-white rounded disabled:opacity-50"
        >
          Previous
        </button>
        <span className="mx-2 px-4 py-2 text-gray-700">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="mx-2 px-4 py-2 bg-green-500 text-white rounded disabled:opacity-50"
        >
          Next
        </button>
      </div>

      {showPaymentModal && selectedStudent && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-auto">
            <h3 className="text-xl font-semibold mb-4 text-gray-700">
              Record Payment for {selectedStudent.name}
            </h3>
            <form onSubmit={handleSubmitPayment}>
              <div className="mb-4">
                <label
                  htmlFor="amount"
                  className="block text-sm font-medium text-gray-700"
                >
                  Amount
                </label>
                <input
                  type="number"
                  id="amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  className="mt-1 p-2 border border-gray-300 rounded w-full bg-slate-50 text-gray-700"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="method"
                  className="block text-sm font-medium text-gray-700"
                >
                  Payment Method
                </label>
                <select
                  id="method"
                  value={method}
                  onChange={(e) => setMethod(e.target.value)}
                  className="mt-1 p-2 border border-gray-300 rounded w-full bg-slate-50 text-gray-700"
                  required
                >
                  <option value="">Select a method</option>
                  <option value="Cash">Cash</option>
                  <option value="Card">Card</option>
                  <option value="Online">Online</option>
                </select>
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => setShowPaymentModal(false)}
                  className="bg-gray-300 hover:bg-gray-400 text-gray-700 font-bold py-2 px-4 rounded mr-2"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                >
                  Submit Payment
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {showHistoryModal && selectedStudent && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50 ">
          <div className="bg-white rounded-lg shadow-lg p-6 w-96">
            <h3 className="text-xl font-semibold mb-4 text-gray-700">
              Payment History for {selectedStudent.name}
            </h3>
            <table className="min-w-full bg-white shadow-md rounded overflow-hidden">
              <thead className="text-white bg-gray-800">
                <tr>
                  <th className="py-2 px-4">Amount</th>
                  <th className="py-2 px-4">Method</th>
                  <th className="py-2 px-4">Date</th>
                  <th className="py-2 px-4"></th>
                </tr>
              </thead>
              <tbody className="bg-gray-100">
                {paymentHistory.map((payment) => (
                  <tr key={payment._id} className="border-b text-gray-700">
                    <td className="py-2 px-4">
                      ₹{formatIndianNumber(payment.amount)}
                    </td>
                    <td className="py-2 px-4">{payment.method}</td>
                    <td className="py-2 px-4">
                      {new Date(payment.date).toLocaleString()}
                    </td>
                    <td>
                      <button
                        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2  rounded mr-2"
                        onClick={() => {
                          downloadFunction(payment);
                        }}
                      >
                         <FontAwesomeIcon
            icon={faDownload}
            className="px-2"
          />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setShowHistoryModal(false)}
                className="bg-gray-300 hover:bg-gray-400 text-gray-700 font-bold py-2 px-4 rounded"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentEntry;
