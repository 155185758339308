import React, { useState, useEffect } from 'react';
import API from '../components/api/api';
import { useDarkMode } from '../Context/context';

const AddEditFeeStructure = () => {
  const [studentClass, setStudentClass] = useState('');
  const [academicYear, setAcademicYear] = useState('');
  const [amount, setAmount] = useState('');
  const [feeStructures, setFeeStructures] = useState([]);
  const [currentId, setCurrentId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isDarkMode } = useDarkMode();

  useEffect(() => {
    const fetchAllFeeStructures = async () => {
      const { data } = await API.get('/fee-structures');
      setFeeStructures(data);
    };
    fetchAllFeeStructures();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = { class: studentClass, academicYear, amount };

    if (currentId) {
      await API.put(`/fee-structures/${currentId}`, payload);
    } else {
      await API.post('/fee-structures', payload);
    }

    setIsModalOpen(false);
    refreshFeeStructures();
    resetForm();
  };

  const handleDelete = async (structureId) => {
    try {
      if (window.confirm('Are you sure you want to delete this fee structure?')) {
        await API.delete(`/fee-structures/${structureId}`);
        setFeeStructures(feeStructures.filter((structure) => structure._id !== structureId));
        alert('Fee structure deleted successfully');
      }
    } catch (error) {
      alert(`Failed to delete fee structure: ${error.response?.data?.message || error.message}`);
    }
  };

  const openModal = (structure = null) => {
    if (structure) {
      setCurrentId(structure._id);
      setStudentClass(structure.class);
      setAcademicYear(structure.academicYear);
      setAmount(structure.amount);
    } else {
      resetForm();
    }
    setIsModalOpen(true);
  };

  const resetForm = () => {
    setCurrentId(null);
    setStudentClass('');
    setAcademicYear('');
    setAmount('');
  };

  const refreshFeeStructures = async () => {
    const { data } = await API.get('/fee-structures');
    setFeeStructures(data);
  };

  const formatIndianNumber = (number) => {
    const numberString = number.toString();
    const lastThree = numberString.slice(-3);
    const others = numberString.slice(0, -3);
    if (others !== '') {
      const formattedOthers = others.replace(/\B(?=(\d{2})+(?!\d))/g, ',');
      return `${formattedOthers},${lastThree}`;
    }
    return lastThree;
  };

  return (
    <div className={`p-6 min-h-full bg-gray-200 overflow-hidden ${isDarkMode ? 'dark-mode' : ''}`}>
      <div className="flex justify-between items-center mb-4">
        <button
          onClick={() => openModal()}
          className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-lg transition duration-300 ease-in-out"
        >
          Add Fee Structure
        </button>
      </div>

      {/* Display All Fee Structures */}
      <div className="mt-8 bg-white shadow-lg rounded-lg p-8 w-full max-w-4xl mx-auto">
        <h2 className="text-xl font-bold mb-4 text-center text-gray-700">All Fee Structures</h2>
        <table className="min-w-full bg-white">
          <thead>
            <tr className='text-gray-700'>
              <th className="py-2">Group</th>
              <th className="py-2">Academic Year</th>
              <th className="py-2">Fee Structure</th>
              <th className="py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {feeStructures.map((structure) => (
              <tr key={structure._id} className="text-center text-gray-700">
                <td className="py-2">{structure.class}</td>
                <td className="py-2">{structure.academicYear}</td>
                <td className="py-2">
                  {formatIndianNumber(structure.amount)}
                </td>

                <td className="py-2 flex justify-center">
                  <button
                    onClick={() => openModal(structure)}
                    className="mr-2 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-1 px-3 rounded-lg transition duration-300 ease-in-out"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(structure._id)}
                    className="bg-red-500 hover:bg-red-600 text-white font-semibold py-1 px-3 rounded-lg transition duration-300 ease-in-out"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal for Add/Edit Fee Structure */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-md">
            <h1 className="text-2xl font-bold mb-6 text-center text-gray-700">{currentId ? 'Edit' : 'Add'} Fee Structure</h1>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <select
                  value={studentClass}
                  onChange={(e) => setStudentClass(e.target.value)}
                  required
                  className="w-full px-4 py-2 border border-gray-300 bg-slate-50 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-700"
                >
                  <option value="">Select Group</option>
                  <option value="Eletrical">Eletrical</option>
                  <option value="Mechanical">Mechanical</option>
                  <option value="Civil">Civil</option>
                  {/* Add more options as needed */}
                </select>
              </div>
              <div>
                <input
                  type="text"
                  value={academicYear}
                  onChange={(e) => setAcademicYear(e.target.value)}
                  placeholder="Academic Year"
                  required
                  className="w-full px-4 py-2 border border-gray-300 bg-slate-50  rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-700"
                />
              </div>
              <div>
              <input
                type="text"
                value={amount ? formatIndianNumber(amount) : ''}
                onChange={(e) => {
                  const inputValue = e.target.value.replace(/,/g, ''); // Remove commas for parsing

                  // Ensure the value is a number or an empty string
                  if (inputValue === '' || !isNaN(inputValue)) {
                    setAmount(inputValue);
                  }
                }}
                placeholder="Amount"
                required
                className="w-full px-4 py-2 border border-gray-300 bg-slate-50 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-700"
              />
            </div>
              <div className="flex justify-between">
                <button
                  type="button"
                  onClick={() => setIsModalOpen(false)}
                  className="w-full mr-2 bg-gray-500 hover:bg-gray-600 text-white font-semibold py-2 px-4 rounded-lg transition duration-300 ease-in-out"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="w-full ml-2 bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-lg transition duration-300 ease-in-out"
                >
                  {currentId ? 'Update' : 'Add'} Fee Structure
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddEditFeeStructure;
