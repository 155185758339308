import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import API from "../components/api/api";
import { useDarkMode } from "../Context/context";

const AddEditStudent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [studentClass, setStudentClass] = useState("");
  const [academicYear, setAcademicYear] = useState("");
  const [feeStructures, setFeeStructures] = useState([]);
  const [feeStructure, setFeeStructure] = useState(null);
  const { isDarkMode } = useDarkMode();
  const [discount, setDiscount] = useState(0);
  const [regNumber, setRegNumber] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    const fetchFeeStructures = async () => {
      try {
        const { data } = await API.get("/fee-structures");
        setFeeStructures(data);
      } catch (error) {
        console.error("Error fetching fee structures:", error);
      }
    };

    const fetchStudent = async () => {
      if (id) {
        try {
          const { data } = await API.get(`/students/${id}`);
          setName(data.name);
          setStudentClass(data.class);
          setAcademicYear(data.academicYear);
          setFeeStructure(data.feeStructure);
          setDiscount(data.discount || 0);
          setRegNumber(data.regNumber || "");
          setPhone(data.phone || "");
          setEmail(data.email || "");
        } catch (error) {
          console.error("Error fetching student data:", error);
        }
      }
    };

    fetchFeeStructures().then(fetchStudent); // Ensure fee structures are fetched before student data
  }, [id]);

  useEffect(() => {
    if (studentClass && academicYear) {
      const matchedFee = feeStructures.find(
        (fee) =>
          fee.class === studentClass && fee.academicYear === academicYear
      );
      if (matchedFee) {
        setFeeStructure(matchedFee.amount);
      } else {
        setFeeStructure("");
      }
    }
  }, [studentClass, academicYear, feeStructures]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const studentData = {
      name,
      regNumber,
      phone,
      email,
      class: studentClass,
      academicYear,
      feeStructure,
      discount,
    };

    try {
      if (id) {
        // Update existing student
        await API.put(`/students/${id}`, studentData);
      } else {
        // Create a new student
        await API.post("/students", studentData);
      }
      navigate("/students");
    } catch (error) {
      console.error("Error saving student data:", error);
    }
  };

  const formatIndianNumber = (number) => {
    const numberString = number.toString();
    const lastThree = numberString.slice(-3);
    const others = numberString.slice(0, -3);
    if (others !== '') {
      const formattedOthers = others.replace(/\B(?=(\d{2})+(?!\d))/g, ',');
      return `${formattedOthers},${lastThree}`;
    }
    return lastThree;
  };
  
  return (
    <div
      className={`flex  justify-center min-h-full  overflow-hidden bg-gray-200 ${
        isDarkMode ? "dark-mode" : ""
      }`}
    >
      <div className="w-full max-w-4xl p-8 bg-white max-h-fit mb-64 mt-24 text-white shadow-lg rounded-lg">
        <h1 className="text-3xl font-bold mb-8 text-center">
          {id ? "Edit Student" : "Add Student"}
        </h1>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
                required
                className="w-full px-4 py-3 bg-gray-900 border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <input
                type="text"
                value={regNumber}
                onChange={(e) => setRegNumber(e.target.value)}
                placeholder="Registration Number"
                required
                className="w-full px-4 py-3 bg-gray-900 border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <input
                type="tel"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Phone"
                required
                className="w-full px-4 py-3 bg-gray-900 border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
                className="w-full px-4 py-3 bg-gray-900 border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <select
                value={studentClass}
                onChange={(e) => setStudentClass(e.target.value)}
                required
                className="w-full px-4 py-3 bg-gray-900 border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="">Select Group</option>
                {Array.from(
                  new Set(feeStructures.map((fee) => fee.class))
                ).map((feeClass) => (
                  <option key={feeClass} value={feeClass}>
                    {feeClass}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <select
                value={academicYear}
                onChange={(e) => setAcademicYear(e.target.value)}
                required
                className="w-full px-4 py-3 bg-gray-900 border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="">Select Academic Year</option>
                {Array.from(
                  new Set(feeStructures.map((fee) => fee.academicYear))
                ).map((feeYear) => (
                  <option key={feeYear} value={feeYear}>
                    {feeYear}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <input
                type="text"
                value={feeStructure ? formatIndianNumber(feeStructure) : ""}
                readOnly
                placeholder="Fee Structure"
                className="w-full px-4 py-3 bg-gray-900 border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <input
                type="number"
                value={discount || ""}
                onChange={(e) => setDiscount(e.target.value)}
                placeholder="Discount Amount"
                className="w-full px-4 py-3 bg-gray-900 border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>
          <div className="flex justify-end mt-8">
            <button
              type="button"
              onClick={() => navigate("/students")}
              className="mr-4 px-6 py-3 bg-gray-500 hover:bg-gray-600 text-white font-semibold rounded-lg transition duration-300 ease-in-out"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-6 py-3 bg-green-500 hover:bg-green-600 text-white font-semibold rounded-lg transition duration-300 ease-in-out"
            >
              {id ? "Update" : "Add"} Student
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddEditStudent;
