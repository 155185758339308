import React, { useState, useEffect } from "react";
import API from "../components/api/api";
import { useDarkMode } from "../Context/context";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StudentPromotion = () => {
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [newClass, setNewClass] = useState("");
  const { isDarkMode } = useDarkMode();
  const [searchQuery, setSearchQuery] = useState("");
  const [classFilter, setClassFilter] = useState("");
  const [yearFilter, setYearFilter] = useState("");
  const [selectAll, setSelectAll] = useState(false); // State to handle "Select All" checkbox
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Fetch students and update filter options
  useEffect(() => {
    const fetchStudents = async () => {
      const { data } = await API.get(
        `/students?page=${currentPage}&className=${classFilter}&searchName=${searchQuery}&academicYear=${yearFilter}`
      );

      // Filter for only "1st Year" students initially
      setStudents(data.students);
      setTotalPages(data.totalPages);
    };
    fetchStudents();
  }, [currentPage, searchQuery, classFilter, yearFilter]);

  // Generate unique class and academic year options
  const uniqueClasses = [...new Set(students.map((student) => student.class))];
  const uniqueAcademicYears = [
    ...new Set(students.map((student) => student.academicYear)),
  ];

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleClassFilterChange = (e) => {
    setClassFilter(e.target.value);
  };

  const handleYearFilterChange = (e) => {
    setYearFilter(e.target.value);
  };

  const handleCheckboxChange = (studentId) => {
    setSelectedStudents((prevSelected) =>
      prevSelected.includes(studentId)
        ? prevSelected.filter((id) => id !== studentId)
        : [...prevSelected, studentId]
    );
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedStudents([]); // Unselect all students
    } else {
      setSelectedStudents(filteredStudents.map((student) => student._id)); // Select all students
    }
    setSelectAll(!selectAll);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await API.post("/promotions/promote", { selectedStudents, newClass });
    alert("Students promoted successfully");
  };

  const formatIndianNumber = (number) => {
    if (number === undefined || number === null) return "";
    const numberString = number.toString();
    const lastThree = numberString.slice(-3);
    const others = numberString.slice(0, -3);
    if (others !== "") {
      const formattedOthers = others.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
      return `${formattedOthers},${lastThree}`;
    }
    return lastThree;
  };

  return (
    <div
      className={`p-6 min-h-full bg-gray-200 overflow-hidden ${
        isDarkMode ? "dark-mode" : ""
      }`}
    >
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold text-gray-700">Promote Students</h1>
        <button
          type="submit"
          onClick={handleSubmit}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Promote
        </button>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex items-center mb-4 space-x-4">
          <div className="relative w-1/3">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearch}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 bg-slate-50 text-gray-700 shadow-sm"
            />
            <FontAwesomeIcon
              icon={faSearch}
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
            />
          </div>

          <select
            value={yearFilter}
            onChange={handleYearFilterChange}
            className="p-2 border border-gray-300 rounded bg-slate-50 text-gray-700"
          >
            <option value="">All Academic Years</option>
            {uniqueAcademicYears.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
        <table className="min-w-full bg-white shadow-md rounded overflow-hidden">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th className="py-2 px-4">
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                />
              </th>
              <th className="py-2 px-4">Name</th>
              <th className="py-2 px-4">Class</th>
              <th className="py-2 px-4">Academic Year</th>
              <th className="py-4 px-4">Due Amount</th>
            </tr>
          </thead>
          <tbody className="bg-gray-100 text-center text-gray-700">
            {students.map((student) => (
              <tr key={student._id} className="border-b border-gray-200">
                <td className="py-2 px-4">
                  <input
                    type="checkbox"
                    checked={selectedStudents.includes(student._id)}
                    onChange={() => handleCheckboxChange(student._id)}
                  />
                </td>
                <td className="py-2 px-4 ">{student.name}</td>
                <td className="py-2 px-4 ">{student.class}</td>
                <td className="py-2 px-4">{student.academicYear}</td>
                <td className="py-4 px-4">
                  ₹{formatIndianNumber(student.dueAmount)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </form>
      <div className="flex justify-center my-4">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="mx-2 px-4 py-2 bg-red-500 text-white rounded disabled:opacity-50"
        >
          Previous
        </button>
        <span className="mx-2 px-4 py-2 text-gray-700">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="mx-2 px-4 py-2 bg-green-500 text-white rounded disabled:opacity-50"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default StudentPromotion;
