import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import API from "../components/api/api";
import { useDarkMode } from "../Context/context";

const StudentProfile = () => {
  const { id } = useParams();
  const [student, setStudent] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState("");
  const [studentClass, setStudentClass] = useState("");
  const [feeStatus, setFeeStatus] = useState("");
  const navigate = useNavigate();
  const { isDarkMode } = useDarkMode();

  useEffect(() => {
    const fetchStudent = async () => {
      try {
        const { data } = await API.get(`/students/${id}`);
        setStudent(data);
        setName(data.name);
        setStudentClass(data.class);
        setFeeStatus(data.feeStatus);
      } catch (error) {
        console.error("Error fetching student data:", error);
      }
    };
    fetchStudent();
  }, [id]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await API.put(`/students/${id}`, {
        name,
        class: studentClass,
        feeStatus,
      });
      setIsEditing(false);
      navigate(`/students/${id}`); // Reload to show updated data
    } catch (error) {
      console.error("Error updating student data:", error);
    }
  };

  return (
    <div
      className={`p-6 min-h-full  bg-gray-200 overflow-hidden ${
        isDarkMode ? "dark-mode" : ""
      }`}
    >
      <div className="flex justify-between items-center mb-4">
        <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-md ">
          <h1 className="text-2xl font-bold mb-6 text-center text-gray-700">
            Student Profile
          </h1>
          {!isEditing ? (
            <>
              <div className="text-lg text-gray-700 mb-6">
                <p className="mb-2">
                  <span className="font-semibold">Name:</span> {student.name}
                </p>
                <p className="mb-2">
                  <span className="font-semibold">Class:</span> {student.class}
                </p>
                <p className="mb-4">
                  <span className="font-semibold">Fee Status:</span>{" "}
                  {student.feeStatus}
                </p>
              </div>
              <button
                onClick={handleEdit}
                className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg transition duration-300 ease-in-out"
              >
                Edit
              </button>
            </>
          ) : (
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Name"
                  required
                  className="w-full px-4 py-2 border border-gray-300 bg-slate-50 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-700"
                />
              </div>
              <div>
                <input
                  type="text"
                  value={studentClass}
                  onChange={(e) => setStudentClass(e.target.value)}
                  placeholder="Class"
                  required
                  className="w-full px-4 py-2 border border-gray-300 bg-slate-50 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-700"
                />
              </div>
              <div>
                <input
                  type="text"
                  value={feeStatus}
                  onChange={(e) => setFeeStatus(e.target.value)}
                  placeholder="Fee Status"
                  required
                  className="w-full px-4 py-2 border border-gray-300 bg-slate-50 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-700"
                />
              </div>
              <div className="flex justify-between">
                <button
                  type="button"
                  onClick={handleCancel}
                  className="w-full mr-2 bg-gray-500 hover:bg-gray-600 text-white font-semibold py-2 px-4 rounded-lg transition duration-300 ease-in-out"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="w-full ml-2 bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-lg transition duration-300 ease-in-out"
                >
                  Save
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default StudentProfile;
