import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const DarkModeContext = createContext();

const DarkModeProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Initialize user to null
  const currentLocation = useLocation(); // Renaming location to avoid conflict
  const navigate = useNavigate();

  useEffect(() => {
    // Retrieve userInfo from localStorage and set it to state
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    setUser(userInfo);
    console.log(userInfo);

    // Redirect to home page if no userInfo and not on reset-password page
    if (!userInfo && currentLocation.pathname !== "/reset-password") {
      navigate("/");
    }
  }, [navigate, currentLocation.pathname]);

  useEffect(() => {
    // Clear userInfo from localStorage on logout (when user becomes null)
    if (!user) {
      localStorage.removeItem("userInfo");
    } else {
      // Set the userInfo in localStorage when a user is logged in
      localStorage.setItem("userInfo", JSON.stringify(user));
    }
  }, [user]);

  const [isDarkMode, setIsDarkMode] = useState(() => {
    // Initialize from localStorage if available
    const savedMode = localStorage.getItem("isDarkMode");
    return savedMode === "true";
  });

  useEffect(() => {
    // Store dark mode preference in localStorage
    localStorage.setItem("isDarkMode", isDarkMode);
  }, [isDarkMode]);

  // Toggle dark mode
  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  return (
    <DarkModeContext.Provider
      value={{
        isDarkMode,
        toggleDarkMode,
        user,
        setUser,
      }}
    >
      {children}
    </DarkModeContext.Provider>
  );
};

// Custom hook to use Dark Mode context
export const useDarkMode = () => {
  return useContext(DarkModeContext);
};

export default DarkModeProvider;
