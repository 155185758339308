import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/common/Navbar';
import Sidebar from './components/common/Sidebar';
import Dashboard from './components/Dashboard';
import LoginPage from './components/LoginPage';
import StudentList from './components/StudentList';
import AddEditFeeStructure from './components/AddEditFeeStructure';
import AddEditStudent from './components/AddEditStudent';
import PaymentEntry from './components/PaymentEntry';
import StudentProfile from './components/StudentProfile';
import StudentPromotion from './components/StudentPromotion';
import PasswordResetPage from './components/PasswordResetPage';

const Layout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const showSidebarAndNavbar = !['/', '/reset-password'].includes(location.pathname);

  return (
    <div className="flex h-screen overflow-hidden">
      {showSidebarAndNavbar && (
        <>
          <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
          <div className="flex-1 flex flex-col">
            <Navbar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
            <div className="flex-1 overflow-hidden">
              {children}
            </div>
          </div>
        </>
      )}
      {!showSidebarAndNavbar && (
        <div className="flex-1 flex flex-col overflow-auto">
          {children}
        </div>
      )}
    </div>
  );
};

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Check for token in localStorage to verify if the user is logged in
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  // Function to handle successful login
  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  return (

      <Layout>
        <Routes>
        <Route path="/" element={<LoginPage onLogin={handleLogin} />} />

        {/* Route for password reset that doesn't require login */}
        <Route path="/reset-password" element={<PasswordResetPage />} />

        {/* Protected routes */}
        {isLoggedIn ? (
          <>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/students" element={<StudentList />} />
          <Route path="/students/:id" element={<StudentProfile />} />
          <Route path="/students/add" element={<AddEditStudent />} />
          <Route path="/students/edit/:id" element={<AddEditStudent />} />
          <Route path="/fee-structures" element={<AddEditFeeStructure />} />
          <Route path="/payments" element={<PaymentEntry />} />
          <Route path="/promotions" element={<StudentPromotion />} />
            </>
        ) : (
          // Redirect to login page if not logged in
          <Route path="*" element={<LoginPage onLogin={handleLogin} />} />
        )}
      </Routes>
      </Layout>
   
  );
};

export default App;
