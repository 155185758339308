import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faHome, faUser, faCog, faDashboard, faDatabase, faTv, faChartBar, faMoneyBill, faHiking, faStar, faAward, faMoneyBillWave, faMoneyBillTrendUp, faMoneyBillTransfer } from '@fortawesome/free-solid-svg-icons';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  return (
    <div className={`bg-gray-800 text-white h-screen ${isOpen ? 'w-64' : 'w-16'} transition-width duration-300`}>
      <div className="p-4 flex justify-between items-center">
        <h1 className={`text-xl font-bold ${isOpen ? 'block' : 'hidden'} uppercase text-white-400`}>
       
        </h1>
        
      </div>
      <div className="mt-4">
        <ul>
          <li className="px-4 py-3 flex items-center">
            <NavLink to="/dashboard" className="flex items-center space-x-2">
              <FontAwesomeIcon icon={faChartBar} />
              <span className={`font-bold text-white-500 ${isOpen ? 'block' : 'hidden'}`}>
                Dashboard
              </span>
            </NavLink>
          </li>
          <li className="px-4 py-3 flex items-center">
            <NavLink to="/fee-structures" className="flex items-center space-x-2">
              <FontAwesomeIcon icon={faMoneyBillTrendUp} />
              <span className={`font-bold text-white-500 ${isOpen ? 'block' : 'hidden'}`}>
                Manage Fee Structure
              </span>
            </NavLink>
          </li>
          <li className="px-4 py-3 flex items-center">
            <NavLink to="/students" className="flex items-center space-x-2">
              <FontAwesomeIcon icon={faUser} />
              <span className={`font-bold text-white-500 ${isOpen ? 'block' : 'hidden'}`}>
                Manage Student
              </span>
            </NavLink>
          </li>
          <li className="px-4 py-3 flex items-center">
            <NavLink to="/payments" className="flex items-center space-x-2">
              <FontAwesomeIcon icon={faMoneyBillTransfer} />
              <span className={`font-bold text-white-500 ${isOpen ? 'block' : 'hidden'}`}>
                Record Payment
              </span>
            </NavLink>
          </li>
          {/* <li className="px-4 py-3 flex items-center">
            <NavLink to="/promotions" className="flex items-center space-x-2">
              <FontAwesomeIcon icon={faAward} />
              <span className={`font-bold text-white-500 ${isOpen ? 'block' : 'hidden'}`}>
                Promote Students
              </span>
            </NavLink>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
