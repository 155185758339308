import React, { useEffect, useState } from "react";
import API from "../components/api/api";
import { useDarkMode } from "../Context/context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAward,
  faDashboard,
  faMoneyBillTransfer,
  faMoneyBillTrendUp,
  faMoneyBillWheat,
  faMoneyCheck,
  faMoneyCheckAlt,
  faMoneyCheckDollar,
  faUser,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";

const Dashboard = () => {
  const [summary, setSummary] = useState({});
  const [notifications, setNotifications] = useState([]);
  const { isDarkMode, toggleDarkMode } = useDarkMode(); // Use the custom hook

  useEffect(() => {
    const fetchSummary = async () => {
      const { data } = await API.get("/auth/getDetails");
      setSummary(data);
      setNotifications([
        "Fees collection deadline is approaching.",
        "3 students have outstanding dues.",
      ]);
    };
    fetchSummary();

    // Disable body scroll
    document.body.style.overflow = "hidden";

    return () => {
      // Re-enable scroll when component unmounts
      document.body.style.overflow = "auto";
    };
  }, []);


  const formatIndianNumber = (number) => {
    if (number === undefined || number === null || isNaN(number)) {
      return ''; // Return an empty string or handle this case as needed
    }
  
    const numberString = number.toString();
    const lastThree = numberString.slice(-3);
    const others = numberString.slice(0, -3);
    
    if (others !== '') {
      const formattedOthers = others.replace(/\B(?=(\d{2})+(?!\d))/g, ',');
      return `${formattedOthers},${lastThree}`;
    }
    
    return lastThree;
  };
  


  return (
    <div
      className={`p-6  min-h-full bg-gray-200 overflow-hidden  ${
        isDarkMode ? "dark-mode" : ""
      }`}
    >
      <h1 className="text-3xl font-bold mb-6 text-gray-700">Admin Dashboard</h1>

      {/* Summary Widgets */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
        <div className="bg-white shadow-md rounded-lg p-6 flex items-center justify-between">
          <div className="flex items-center">
            <div className="icon-cl p-3 rounded-full mr-4">
              <FontAwesomeIcon icon={faMoneyCheck} size="xl" />
            </div>
            <div>
              <h2 className="text-xl font-semibold text-gray-700">
                Total Fees Collected
              </h2>
              <p className="text-2xl mt-4 text-green-500 font-semibold">
                {formatIndianNumber(summary.totalAmountCollected)}
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white shadow-md rounded-lg p-6 flex items-center justify-between">
          <div className="flex items-center">
            <div className="icon-cl p-3 rounded-full mr-4">
              <FontAwesomeIcon icon={faDashboard} size="xl" />
            </div>
            <div>
              <h2 className="text-xl font-semibold text-gray-700">
                Pending Fees
              </h2>
              <p className="text-2xl mt-4 text-yellow-500 font-semibold">
                {formatIndianNumber(summary.totalFeeDues)}
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white shadow-md rounded-lg p-6 flex items-center justify-between">
          <div className="flex items-center">
            <div className="icon-cl p-3 rounded-full mr-4">
              <FontAwesomeIcon icon={faMoneyCheckAlt} size="xl" />
            </div>
            <div>
              <h2 className="text-xl font-semibold text-gray-700">
                Total Amount
              </h2>
              <p className="text-2xl mt-4 text-green-500 font-semibold">
                  {/* Calculate the total and then format it */}
              {formatIndianNumber(summary.totalFeeDues + summary.totalAmountCollected)}

              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Quick Links */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        <button
          onClick={() => (window.location.href = "/students")}
          className="bg-blue-500 alw-white rounded-lg p-4 shadow-md font-bold hover:bg-blue-600 "
        >
          <FontAwesomeIcon icon={faUser} className="mx-3" />
          Manage Students
        </button>
        <button
          onClick={() => (window.location.href = "/fee-structures")}
          className="bg-green-500 alw-white rounded-lg p-4 shadow-md font-bold hover:bg-green-600"
        >
          <FontAwesomeIcon icon={faMoneyBillTrendUp} className="mx-3" />
          Manage Fee Structures
        </button>
        <button
          onClick={() => (window.location.href = "/payments")}
          className="bg-purple-500 alw-white rounded-lg p-4 shadow-md font-bold hover:bg-purple-600"
        >
          <FontAwesomeIcon icon={faMoneyBillTransfer} className="mx-3" />
          Record Payment
        </button>
        <button
          onClick={() => (window.location.href = "/dashboard")}
          className="bg-red-500 alw-white rounded-lg p-4 shadow-md font-bold hover:bg-red-600"
        >
          <FontAwesomeIcon icon={faAward} className="mx-3" />
          Promote Students
        </button>
      </div>

      {/* Notifications/Alerts */}
      {/* {notifications.length > 0 && (
        <div className="mt-6">
          {notifications.map((notification, index) => (
            <div key={index} className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4">
              <p>{notification}</p>
            </div>
          ))}
        </div>
      )} */}
    </div>
  );
};

export default Dashboard;
