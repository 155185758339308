import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import API from "../components/api/api";
import { useDarkMode } from "../Context/context";
import { faEdit, faSearch, faUserCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StudentList = () => {
  const [students, setStudents] = useState([]);
  const [feeStructures, setFeeStructures] = useState([]);
  const { isDarkMode } = useDarkMode();
  const [searchQuery, setSearchQuery] = useState("");
  const [yearFilter, setYearFilter] = useState("");
  const [classFilter, setClassFilter] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    const fetchStudents = async () => {
      const { data } = await API.get(
        `/students?page=${currentPage}&className=${classFilter}&academicYear=${yearFilter}&searchName=${searchQuery}`
      );
      setStudents(data.students);
      setTotalPages(data.totalPages);
    };

    const fetchFeeStructures = async () => {
      const { data } = await API.get("/fee-structures");
      setFeeStructures(data);
    };

    fetchStudents();
    fetchFeeStructures();
  }, [currentPage, searchQuery, classFilter, yearFilter]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleYearFilterChange = (e) => {
    setYearFilter(e.target.value);
  };

  const handleClassFilterChange = (e) => {
    setClassFilter(e.target.value);
  };

  // const filteredStudents = students.filter((student) => {
  //   const matchesSearchQuery =
  //     student.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //     student.class.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //     student.academicYear.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //     student.feeStructure.toString().includes(searchQuery); // Include feeStructure in search

  //   const matchesClassFilter = classFilter
  //     ? student.class === classFilter
  //     : true;
  //   const matchesYearFilter = yearFilter
  //     ? student.academicYear === yearFilter
  //     : true;

  //   return matchesSearchQuery && matchesClassFilter && matchesYearFilter;
  // });

  const uniqueClasses = Array.from(
    new Set(feeStructures.map((fee) => fee.class))
  );
  const uniqueYears = Array.from(
    new Set(feeStructures.map((fee) => fee.academicYear))
  );

  const navigateToProfile = (guid) => {
    navigate(`/students/${guid}`);
  };

  const formatIndianNumber = (number) => {
    const numberString = number.toString();
    const lastThree = numberString.slice(-3);
    const others = numberString.slice(0, -3);
    if (others !== "") {
      const formattedOthers = others.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
      return `${formattedOthers},${lastThree}`;
    }
    return lastThree;
  };

  return (
    <div
      className={`p-6 min-h-full bg-gray-200 overflow-hidden ${
        isDarkMode ? "dark-mode" : ""
      }`}
    >
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold text-gray-700">Student List</h1>
        <button
          onClick={() => navigate("/students/add")}
          className={`ml-4 px-4 py-2 rounded ${
            isDarkMode
              ? "bg-green-700 text-gray-100"
              : "bg-green-500 text-white"
          }`}
        >
          Add Student
        </button>
      </div>

      <div className="flex items-center mb-4 space-x-4">
        <div className="relative w-1/3">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 bg-slate-50 text-gray-700 shadow-sm"
          />
          <FontAwesomeIcon
            icon={faSearch}
            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
          />
        </div>
        <select
          value={classFilter}
          onChange={handleClassFilterChange}
          className="p-2 border border-gray-300 rounded bg-slate-50 text-gray-700"
        >
          <option value="">All Groups</option>
          {uniqueClasses.map((uniqueClass, index) => (
            <option key={index} value={uniqueClass}>
              {uniqueClass}
            </option>
          ))}
        </select>
        <select
          value={yearFilter}
          onChange={handleYearFilterChange}
          className="p-2 border border-gray-300 rounded bg-slate-50 text-gray-700"
        >
          <option value="">All Academic Years</option>
          {uniqueYears.map((uniqueYear, index) => (
            <option key={index} value={uniqueYear}>
              {uniqueYear}
            </option>
          ))}
        </select>
      </div>

      <table className="min-w-full bg-white shadow-md rounded overflow-hidden">
        <thead className="text-white bg-gray-800">
          <tr>
            <th className="py-4 px-4">Name</th>
            <th className="py-4 px-4">Group</th>
            <th className="py-4 px-4">Academic Year</th>
            <th className="py-4 px-4">Fee Structure</th>{" "}
            <th className="py-4 px-4">Actions</th>
          </tr>
        </thead>
        <tbody className="bg-gray-100">
          {students.map((student) => (
            <tr
              key={student._id}
              className="border-b text-center border-t border-gray-200"
            >
              <td className="py-2 px-4 text-gray-700">{student.name}</td>
              <td className="py-2 px-4 text-gray-700">{student.class}</td>
              <td className="py-2 px-4 text-gray-700">
                {student.academicYear}
              </td>
              <td className="py-2 px-4 text-gray-700">
                {formatIndianNumber(student.feeStructure)}
              </td>
              <td className="py-2 px-4 text-gray-700">
                <button
                  onClick={() => navigate(`/students/edit/${student.guid}`)}
                  className={`px-2 py-1 rounded mx-2 ${
                    isDarkMode ? " text-gray-100" : " text-white"
                  }`}
                >
                  <FontAwesomeIcon icon={faEdit} className="text-yellow-500" />
                </button>
                <button
                  onClick={() => navigateToProfile(student.guid)}
                  className={`px-2 py-1 rounded ${
                    isDarkMode ? " text-gray-100" : " text-white"
                  }`}
                >
                  <FontAwesomeIcon
                    icon={faUserCog}
                    className="text-green-500"
                  />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-center my-4">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="mx-2 px-4 py-2 bg-red-500 text-white rounded disabled:opacity-50"
        >
          Previous
        </button>
        <span className="mx-2 px-4 py-2 text-gray-700">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="mx-2 px-4 py-2 bg-green-500 text-white rounded disabled:opacity-50"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default StudentList;
