import axios from "axios";

const API = axios.create({
  baseURL: "https://fee-6jw9.onrender.com/api",
});

// Request Interceptor to add token
API.interceptors.request.use(
  (req) => {
    const token = localStorage.getItem("token");
    if (token) {
      req.headers["Authorization"] = `Bearer ${token}`;
    }
    return req;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Response Interceptor to handle errors
API.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle response error
    if (error.response && error.response.status === 401) {
      // Handle unauthorized errors (e.g., redirect to login)
      console.error("Unauthorized. Redirecting to login...");
    }
    return Promise.reject(error);
  }
);

export default API;
