import React, { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faBell, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import ToggleSwitch from '../../components/common/ToggleSwitch';
import { useDarkMode } from '../../Context/context'; // Adjust path as needed

const Navbar = ({ isSidebarOpen, toggleSidebar }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const { isDarkMode, toggleDarkMode } = useDarkMode(); // Use the custom hook

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const logoutHandler = () => {
    navigate('/');
    window.location.reload();
  };

  return (
    <header
      className={`bg-navbar p-4 shadow flex justify-between items-center ${isDarkMode ? 'dark-mode' : ''}`}
    >
      <div className="flex items-center">
        <button onClick={toggleSidebar} className="mr-4">
          <FontAwesomeIcon icon={faList} className="text-gray-700" />
        </button>
        <ToggleSwitch isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />
      </div>
      <div className="flex items-center ml-auto relative">
        <div className="relative" ref={dropdownRef}>
          <div className="flex items-center cursor-pointer" onClick={toggleDropdown}>
            <img src="https://png.pngtree.com/png-clipart/20211017/original/pngtree-school-logo-png-image_6851480.png" alt="Profile" className="h-8 w-8 mr-3 rounded-sm" />
            <FontAwesomeIcon icon={faBell} className="text-blue-500 mr-4" />
          </div>
          {dropdownVisible && (
            <div className="absolute right-0 mt-2 mx-0 w-48 bg-dropdown border border-gray-200 rounded shadow-lg z-50">
              <ul className="py-1">
                <li>
                  <button
                    onClick={logoutHandler}
                    className="block w-full text-left px-4 py-2 text-red-500 hover:bg-gray-100 font-bold transition-colors duration-200 ease-in-out"
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" /> Logout
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Navbar;
